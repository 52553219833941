import Swal from "sweetalert2";
import {useTranslate} from "@/hooks/translate.hook";

const t = useTranslate()
const sab = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-sm btn-primary m-1',
        cancelButton: 'btn btn-sm btn-secondary m-1',
        denyButton: 'btn btn-sm btn-secondary m-1',
        popup: 'card text-body'
    },
    buttonsStyling: false
})

export class AlertManager {

    static async alert(title: string, desc: string) {

        return await sab.fire({
            title: title,
            text: desc,
            icon: "success"
        });

    }

    public static confirm(title: string, desc: string, confirm: string, deny: string, callback: Function): void {
        sab.fire({
            title: title,
            text: desc,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: deny,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                callback();
            }
        })
    }

    public static showLoading() {
        return sab.fire({
            title: t('processing.title'),
            text: t('processing.desc'),
            showCloseButton: false,
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false
        })
    }

    static async error(message: string) {
        return await sab.fire({
            title: t('error.general.regular'),
            text: message,
            icon: 'error',
            confirmButtonText: t('error.general.button')
        })
    }

    static async fatal(message: string) {
        return await sab.fire({
            title: t('error.general.fatal'),
            icon: "error",
            text: message,
            showCloseButton: false,
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false
        })
    }

    static async warning(title: string, desc: string) {

        return await sab.fire({
            title: title,
            text: desc,
            icon: "warning"
        });

    }
}