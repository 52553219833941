<template>
  <Navigation/>
  <Layout>
    <template v-slot:main>
      <div class="card mb-3">
        <h5 class="card-header">{{ t('page.setup.title') }}</h5>
        <div class="card-body">
          <p>{{ t('page.setup.text.scenarios.text') }}</p>
          <ul>
            <li>{{ t('page.setup.text.scenarios.list.0') }}</li>
            <li>{{ t('page.setup.text.scenarios.list.1') }}</li>
          </ul>
          <p>{{ t('page.setup.text.first') }}</p>
          <p>{{ t('page.setup.text.second') }}</p>
          <div class="row align-items-baseline">
            <div class="col mx-auto text-center">
              <h5>Google Chrome</h5>
              <img src="@/assets/chrome.png" alt="chrome">
              <img src="@/assets/chrome2.png" alt="chrome">
            </div>
            <div class="col mx-auto text-center">
              <h5>Firefox</h5>
              <img src="@/assets/firefox.png" alt="firefox">
            </div>
          </div>
          <div class="btn-group float-end mt-3">
            <RouterLink to="/setup/camera" class="btn btn-sm btn-primary">
              {{ t('page.setup.start') }}
            </RouterLink>
            <RouterLink to="/list" class="btn btn-sm btn-secondary">
              {{ t('page.setup.return') }}
            </RouterLink>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import Layout from '@/components/Layout.vue';
import Navigation from '@/components/Navigation.vue';
import {useHasActiveTest} from "@/hooks/hastest.hook";
import {onBeforeMount} from "vue";
import {useTranslate} from "@/hooks/translate.hook";

const t = useTranslate()
const hasTest = useHasActiveTest()
onBeforeMount(() => hasTest())
</script>