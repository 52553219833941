<template>
  <div class="row mb-5">
    <div class="col-lg-10">
      <slot name="main"/>
    </div>
    <div class="col-lg-2">
      <div class="card mb-3" v-if="student">
        <slot name="image">
          <img class="card-img-top" :src="studentImgUrl" alt="student" v-if="displayStudentImage">
        </slot>
        <h5 class="card-header text-center">{{ t("layout.student") }}</h5>
        <div class="card-body text-center">
          <p class="card-text">
            {{ student.index }}<br>
            {{ student.firstName }} {{ student.lastName }}<br>
          </p>
          <p v-if="student.isAllowedOnline || student.isEmployed">
            <i class="fa-solid fa-globe me-1" :title="t('layout.online')" v-if="student.isAllowedOnline"></i>
            <i class="fa-solid fa-suitcase" :title="t('layout.employed')" v-if="student.isEmployed"></i>
          </p>
          <hr>
          <p class="card-text">{{ student.faculty.name }}</p>
          <slot name="button">
            <button type="button" class="btn btn-sm btn-primary" @click="logout()">
              {{ t("layout.button") }}
            </button>
          </slot>
        </div>
      </div>
      <div class="card mb-3" v-if="time">
        <h5 class="card-header text-center">{{ t("layout.time") }}</h5>
        <div class="card-body text-center time">{{ time }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {MainService} from '@/services/main.service';
import {onBeforeMount, onMounted, ref} from 'vue';
import {SessionManager} from '@/services/session.manager';
import {StudentModel} from "@/models/student.model";
import {useLogout} from "@/hooks/logout.hook";
import {useTranslate} from "@/hooks/translate.hook";

const displayStudentImage = process.env.VUE_APP_DISPLAY_STUDENT_IMAGE === "true"
const time = ref<string>()
const srvTime = ref<Date>(new Date())
const logout = useLogout()
const studentImgUrl = ref()
const t = useTranslate()

const student = ref<StudentModel>()
MainService.getStudent().then(rsp => {
  studentImgUrl.value = `https://ecms.singidunum.ac.rs/${rsp.data.index}`
  student.value = rsp.data
}).catch(e => {
  logout()
})

onMounted(async () => {
  const rsp = await MainService.getServerTime()
  srvTime.value = new Date(rsp.data.time)
  setInterval(function () {
    srvTime.value.setSeconds(srvTime.value.getSeconds() + 1);
    time.value = srvTime.value.toLocaleTimeString('sr-RS')
  }, 1000);
})

onBeforeMount(() => {
  // You are not logged in, go back to login page
  if (!SessionManager.hasAuth()) {
    logout()
  }
})
</script>

<style>
.time {
  font-size: large;
  font-weight: bold;
}
</style>