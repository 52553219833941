<template>
  <nav class="navbar navbar-expand-lg mb-3">
    <div class="container">
      <RouterLink class="navbar-brand" :to="(props.show) ? '/' : '#'" v-if="props.logo">
        <img src="@/assets/logo.svg" class="logo">
      </RouterLink>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-if="props.show">
          <NavbarRoute :name="t('navigation.list')" to="/list" />
          <NavbarRoute :name="t('navigation.setup')" to="/setup" />
          <NavbarRoute :name="t('navigation.results')" to="/results" />
        </ul>
        <div class="me-auto mb-2 mb-lg-0" v-else></div>
        <div class="navbar-text">
          <div class="d-flex flex-row align-items-center text-nowrap" v-if="tutor">
            <i class="fa-solid fa-circle pe-1" :style="color"></i>
            {{ tutor }}<span v-if="location">&nbsp;({{ location.name }})</span>
          </div>
          <select class="form-select" aria-label="Default select example" v-model="lang" @change="handleLangChange">
            <option value="sr" selected>Српски</option>
            <option value="en">English</option>
          </select>
          <div class="form-check form-switch">
            <label for="darkModeSwitch"><i class="fa-solid fa-moon"></i></label>
            <input id="darkModeSwitch" type="checkbox" class="form-check-input" v-model="darkMode"
              @change="toggleDarkMode">
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import NavbarRoute from "@/components/NavbarRoute.vue";
import { setLocale } from "@/services/localisation";
import { useTranslate } from "@/hooks/translate.hook";
import { LocationModel } from "@/models/location.model";
import { MainService } from "@/services/main.service";
import { useRoute } from "vue-router";

const t = useTranslate()
const route = useRoute()

const tutor = process.env.VUE_APP_MTUTOR_NAME
const color = reactive({
  color: process.env.VUE_APP_MTUTOR_COLOR
})

// Handle language in URL
if (route.query.lang) {
  const queryLang = route.query.lang as string
  if (queryLang === "sr" || queryLang === "en") {
    localStorage.setItem('language', queryLang)
    setLocale(queryLang)
  }
}

const lang = ref<string>(localStorage.getItem('language') ?? 'sr')

function handleLangChange() {
  setLocale(lang.value)
}

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
    required: false
  },
  logo: {
    type: Boolean,
    default: true,
    required: false
  }
})

const location = ref<LocationModel>()
if (props.show) {
  MainService.getCurrentLocation().then(rsp => {
    location.value = rsp.data
  })
}

const darkMode = ref<boolean>(false);

onMounted(() => {
  const savedMode = localStorage.getItem('darkMode');
  if (savedMode !== null) {
    darkMode.value = JSON.parse(savedMode);
  } else {
    // Default dark mode
    darkMode.value = true
  }
  toggleDarkMode(); // Apply the mode when the component is mounted
});

const toggleDarkMode = () => {
  document.documentElement.setAttribute('data-bs-theme', (darkMode.value) ? 'dark' : 'light')
  localStorage.setItem('darkMode', JSON.stringify(darkMode.value));
};
</script>

<style>
.logo {
  width: 120px;
  height: 50px;
}

html[data-bs-theme='dark'] .logo {
  filter: invert();
}

.navbar-text {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>