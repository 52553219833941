<template>
  <Navigation :show="false"/>
  <Layout>
    <template v-slot:main>
      <div class="card" v-if="question">
        <div class="card-header">
          <ul class="list-unstyled">
            <li>
              {{ t('page.test.name') }} <strong>{{ question.testName }}</strong>
            </li>
            <li>
              {{ t('page.test.opened') }} <strong>{{ question.totalOpened }}/{{ question.totalQuestions }}</strong>
            </li>
            <li>
              {{ t('page.test.time') }}
              <vue-countdown :time="getCountdownTime()" :interval="1000" @end="()=>onCountdownEndReached()"
                             v-slot="{ days, hours, minutes, seconds, milliseconds }" class="fw-bold">
                <span v-if="hours > 0">{{ addLeadingZero(hours) }}:</span>
                {{ addLeadingZero(minutes) }}:{{ addLeadingZero(seconds) }}
              </vue-countdown>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="card-text">
            <h6 v-html="DOMPurify.sanitize(question.question)" class="mb-5"></h6>
            <table class="table table-hover">
              <tbody ref="tbody">
              <tr v-for="(answ, index) in question.answers" :key="index"
                  :class="(selected.includes(index))? 'test-row table-active' : 'test-row'"
                  @click="selectAnswer(index)">
                <th scope="row" class="test-properties">
                  <input class="form-check-input"
                         type="checkbox"
                         :checked="selected.includes(index)"
                         v-if="question.multiChoice"
                         :disabled="disabled">
                  <input class="form-check-input"
                         type="radio"
                         :checked="selected.includes(index)"
                         :disabled="disabled"
                         v-else>
                </th>
                <td v-html="DOMPurify.sanitize(answ)"></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="btn-group float-end">
            <button type="button" class="btn btn-sm btn-primary" @click="finishTestButtonClickHandler"
                    :disabled="disabled"
                    v-if="question.totalOpened == question.totalQuestions">
              {{ t('page.test.end') }}
            </button>
            <button type="button" class="btn btn-sm btn-primary" @click="nextQuestion" :disabled="disabled"
                    v-else>
              {{ t('page.test.next') }}
            </button>
          </div>
        </div>
      </div>
      <div class="card mb-3" v-else>
        <div class="card-body">
          <p class="card-text">{{ t('page.test.loading') }}</p>
        </div>
      </div>
    </template>
    <template v-slot:image>
      <CameraFeed :workId="question.workId" v-if="question"/>
    </template>
    <template v-slot:button>
      <button type="button" class="btn btn-sm btn-primary" @click="finishTestButtonClickHandler">
        {{ t('page.test.logout.end') }}
      </button>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import Layout from "@/components/Layout.vue";
import CameraFeed from "@/components/CameraFeed.vue";
import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {MainService} from "@/services/main.service";
import {useRouter} from "vue-router";
import * as DOMPurify from "dompurify";
import {QuestionModel} from "@/models/question.model";
import {AlertManager} from "@/services/alert.manager";
import VueCountdown from '@chenfengyuan/vue-countdown';
import Navigation from "@/components/Navigation.vue";
import {useLogout} from "@/hooks/logout.hook";
import {useTranslate} from "@/hooks/translate.hook";

const question = ref<QuestionModel>()
const selected = ref<number[]>([])
const disabled = ref<boolean>(true)
const workId = ref<number>()
const countdownEndReached = ref<boolean>(false);
const showCheatingAlert = ref<boolean>(true);
const logout = useLogout()
const router = useRouter()
const t = useTranslate()

function addLeadingZero(num: number) {
  let numStr = num.toString();
  if (numStr.length === 1) {
    numStr = '0' + numStr;
  }
  return numStr;
}

const isFocused = computed(() => {
  return isWindowFocused.value && isMouseFocused.value;
});

const isWindowFocused = ref(true);
const isMouseFocused = ref(true);

watch([isFocused], async () => {
  if (showCheatingAlert.value) {
    showCheatingAlert.value = false
    MainService.saveStudentCheatingAttempt()
        .then(rsp => console.log('Cheating recorded'))
        .catch(e => (e.message == 'CREATE_FAILED') ? showResult() : logout())
    await AlertManager.warning(
        t('page.test.focus.title'),
        t('page.test.focus.desc'),
    )
    showCheatingAlert.value = true
  }
});

const handleVisibilityChange = () => {
  isWindowFocused.value = !document.hidden;
};

function isMouseInsideWindow(event: MouseEvent): boolean {

  const delta = 10;

  return (
      event.clientX >= delta &&
      event.clientY >= delta &&
      event.clientX <= window.innerWidth - delta &&
      event.clientY <= window.innerHeight - delta
  );
}

function handleMouseMoveEvent(event: MouseEvent) {

  if (isMouseInsideWindow(event)) {
    isMouseFocused.value = true
  } else {
    isMouseFocused.value = false
  }
}

let interval = setInterval(() => {
  isWindowFocused.value = document.hasFocus()
}, 500)

onMounted(() => {
  handleVisibilityChange();
  document.addEventListener('visibilitychange', handleVisibilityChange);
  window.addEventListener('mousemove', handleMouseMoveEvent);
  document.addEventListener('mouseleave', handleMouseMoveEvent)
});

onBeforeUnmount(() => {
  document.removeEventListener('visibilitychange', handleVisibilityChange);
  window.removeEventListener('mousemove', handleMouseMoveEvent);
  document.removeEventListener('mouseleave', handleMouseMoveEvent)
  clearInterval(interval)
});

async function onCountdownEndReached() {
  if (!countdownEndReached.value) {
    countdownEndReached.value = true
    await MainService.finishCurrentStudentTest(question.value!.workId)
    showResult()
  }
}

function selectAnswer(index: number) {
  if (disabled.value) return;
  if (question.value?.multiChoice) {
    // Multiple answers
    if (selected.value.includes(index)) {
      selected.value = selected.value.filter(a => a !== index)
      return
    }

    // Samo dodaj odgovor
    selected.value.push(index)
    return
  }

  // Single choice, remove if it exists
  if (selected.value.length === 1) {
    if (selected.value[0] === index) {
      selected.value = []
      return;
    }

    selected.value = []
    selected.value.push(index)
    return;
  }

  // dodaj odgovor
  selected.value.push(index)
}

function nextQuestion() {
  if (question.value == undefined) {
    return
  }

  disabled.value = true
  const answers = selected.value.map(idx => question.value!.answers[idx])
  question.value = undefined
  MainService.sendAnswers(answers)
      .then(rsp => {
        selected.value = []
        loadNewQuestion()
      })
      .catch(e => {
        if (e.message == 'NOT_FOUND' || e.message == 'CREATE_FAILED' || e.message == "UPDATE_FAILED") {
          showResult()
          return
        }
        logout()
      })
}

function loadNewQuestion() {
  MainService.getNextStudentTestQuestion()
      .then(rsp => {
        question.value = rsp.data
        workId.value = rsp.data.workId
        sessionStorage.setItem('std-work-id', rsp.data.workId)
        disabled.value = false
      })
      .catch(e => {
        // Make sure the work id is undefined if its not parsable
        const parsed = parseInt(sessionStorage.getItem('std-work-id') ?? "")
        workId.value = isNaN(parsed) ? undefined : parsed;

        if (e.message == 'NOT_FOUND' || e.message == 'CREATE_FAILED' || e.message == "UPDATE_FAILED") {
          showResult()
          return
        }
        logout()
      })
}

function finishTestButtonClickHandler() {
  if (question.value == undefined) return
  if (question.value.totalOpened != question.value.totalQuestions) {
    AlertManager.confirm(
        t('page.test.confirm.title'),
        t('page.test.confirm.desc'),
        t('page.test.confirm.yes'),
        t('page.test.confirm.no'),
        () => {
          finishCurrentTest()
        })
    return
  }
  finishCurrentTest()
}

async function finishCurrentTest() {
  if (question.value == undefined) return
  const loading = AlertManager.showLoading()

  try {
    disabled.value = true
    const answers = selected.value.map(idx => question.value!.answers[idx])
    await MainService.sendAnswers(answers)
    await MainService.finishCurrentStudentTest(question.value!.workId)
    loading.close()
    showResult()
  } catch (e: any) {
    console.error(e)
    loading.close()
    if (e.message == 'NOT_FOUND' || e.message == 'CREATE_FAILED' || e.message == "UPDATE_FAILED") {
      showResult()
      return
    }
    logout()
  }
}

function showResult() {
  if (workId.value == undefined) {
    router.push({
      path: '/list'
    })
    return
  }

  router.push({
    path: '/result/' + workId.value
  })
}

function getCountdownTime() {
  if (question.value == undefined)
    return 0
  return new Date(question.value?.deadline).getTime() - new Date().getTime()
}

onMounted(() => {
  // Ucitaj inicijalno pitanje
  loadNewQuestion()
})
</script>

<style>
.card-text img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 60%;
}
</style>