<template>
  <Navigation/>
  <Layout>
    <template v-slot:main>
      <div class="card mb-3">
        <h5 class="card-header">{{t('page.camera.title')}}</h5>
        <div class="card-body">
          <p v-html="t('page.camera.text.0')"></p>
          <p v-html="t('page.camera.text.1')"></p>
          <div class="w-50 mx-auto">
            <CameraFeed class="mb-3"/>
          </div>
          <p v-html="t('page.camera.text.2')" ></p>
          <p v-html="t('page.camera.text.3')" ></p>
          <p v-html="t('page.camera.text.4')" ></p>
          <div class="btn-group float-end">
            <button type="button" class="btn btn-sm btn-primary" @click="runAgain">
              {{t('page.camera.again')}}
            </button>
            <RouterLink to="/list" class="btn btn-sm btn-secondary">
              {{t('page.camera.return')}}
            </RouterLink>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import Layout from '@/components/Layout.vue';
import Navigation from '@/components/Navigation.vue';
import CameraFeed from '@/components/CameraFeed.vue'
import {useHasActiveTest} from "@/hooks/hastest.hook";
import {onBeforeMount} from "vue";
import {useTranslate} from "@/hooks/translate.hook";

const t = useTranslate()
async function runAgain() {
  //@ts-ignore
  const status = await navigator.permissions.query({name: "camera"})
  location.reload()
}

const hasTest = useHasActiveTest()
onBeforeMount(() => hasTest())
</script>