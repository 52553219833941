<template>
  <tr :key="test.testId" :class="(selected)? 'test-row table-active' : 'test-row'">
    <th scope="row" class="test-properties">
      <i :class="getTestType(test).icon" :title="getTestType(test).hover"></i>
    </th>
    <td>
      <span>{{ test.title }}</span>
    </td>
  </tr>
</template>

<script setup lang="ts">
export interface TestProps {
  test: TestModel,
  selected: boolean
}

const {test, selected} = defineProps<TestProps>()

import {TestModel} from '@/models/test.model';

function getTestType(test: TestModel) {
  if (test.requireCamera && test.faceDetection) {
    return {
      icon: 'fa-solid fa-masks-theater',
      hover: 'Укључена детекција лица'
    }
  }

  if (test.requireCamera) {
    return {
      icon: 'fa-solid fa-camera',
      hover: 'Неопходна камера'
    }
  }

  if (test.showAnswers) {
    return {
      icon: 'fa-solid fa-list-check',
      hover: 'Тест за активност на настави'
    }
  }

  return {
    icon: 'fa-solid fa-list',
    hover: 'Обичан тест'
  }
}
</script>

<style>
.test-properties {
  width: 20px;
}

.test-row {
  cursor: pointer;
}
</style>