import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import ListView from '@/views/ListView.vue'
import LoginView from '@/views/LoginView.vue'
import SetupView from '@/views/SetupView.vue'
import ResultView from '@/views/ResultView.vue'
import TestView from '@/views/TestView.vue'
import CameraView from "@/views/CameraView.vue";
import TestResultView from "@/views/TestResultView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/list',
        name: 'list',
        component: ListView
    },
    {
        path: '/setup',
        name: 'setup',
        component: SetupView
    },
    {
        path: '/setup/camera',
        name: 'camera',
        component: CameraView
    },
    {
        path: '/results',
        name: 'results',
        component: ResultView
    },
    {
        path: '/result/:id',
        name: 'result',
        component: TestResultView
    },
    {
        path: '/test',
        name: 'test',
        component: TestView
    },
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/list'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
