<template>
  <Navigation/>
  <Layout>
    <template v-slot:main>
      <div class="card mb-3">
        <h5 class="card-header">{{t("page.results.card.title")}}</h5>
        <div class="card-body" v-if="results">
          <div v-if="results.length > 0">
            <h5>{{t("page.results.card.text")}}</h5>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th scope="col">{{t("page.results.card.table.subject")}}</th>
                <th scope="col">{{t("page.results.card.table.test")}}</th>
                <th scope="col">{{t("page.results.card.table.date")}}</th>
                <th scope="col">{{t("page.results.card.table.result")}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="result in results">
                <th scope="row">{{ result.test.subject.title }}</th>
                <td>{{ result.test.title }}</td>
                <td>{{ formatDate(result.finishedAt) }}</td>
                <td>{{ calcPoints(result) }}/{{ result.maxPoints }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p class="mb-0">{{t("page.results.card.alt")}}</p>
          </div>
          <div class="btn-group float-end">
            <RouterLink to="/list" class="btn btn-sm btn-secondary">
              {{t("page.results.card.button")}}
            </RouterLink>
          </div>
        </div>
        <div class="card-body" v-else>
          {{t("page.results.card.loading")}}
        </div>
      </div>
      <div class="card mb-3">
        <h5 class="card-header"><i class="fa-solid fa-triangle-exclamation"></i> {{t("page.results.note.title")}}</h5>
        <div class="card-body">
          {{t("page.results.note.text")}}
        </div>
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import Layout from '@/components/Layout.vue';
import Navigation from '@/components/Navigation.vue';
import {onBeforeMount, ref} from "vue";
import {useHasActiveTest} from "@/hooks/hastest.hook";
import {WorkModel} from "@/models/work.model";
import {MainService} from "@/services/main.service";
import {AlertManager} from "@/services/alert.manager";
import {useLogout} from "@/hooks/logout.hook";
import {useTranslate} from "@/hooks/translate.hook";

const hasTest = useHasActiveTest()
const logout = useLogout()
const t = useTranslate();
onBeforeMount(() => hasTest())

const results = ref<WorkModel[]>()
MainService.getLatestStudentResults().then(rsp => {
  results.value = rsp.data
}).catch(e => {
  AlertManager.error('Нисмо успели да учитамо резултате')
  logout()
})

function formatDate(timestamp: string) {
  if (timestamp == null) return 'N/A'
  return new Date(timestamp).toLocaleString('sr', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

function calcPoints(result: WorkModel) {
  const points = ((result.numberOfCorrectQuestions ?? 0) / result.numberOfQuestions) * result.maxPoints
  return Math.round(points * 100) / 100
}

</script>