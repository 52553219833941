<template>
  <Navigation/>
  <Layout>
    <template v-slot:main>
      <div class="card mb-3" v-if="result">
        <h5 class="card-header">{{t('page.result.title')}}</h5>
        <div class="card-body">
          <table class="table table-striped">
            <tbody class="mx-auto text-center fixed-columns">
            <tr>
              <th colspan="2">
                <h3>{{ generateTestTitle() }}</h3>
              </th>
            </tr>
            <tr>
              <th>{{t('page.result.subject')}}</th>
              <th>{{ result.subjectName }}</th>
            </tr>
            <tr>
              <th>{{t('page.result.test')}}</th>
              <th>{{ result.testName }}</th>
            </tr>
            <tr>
              <th>{{t('page.result.points')}}</th>
              <th>{{ result.points }}/{{ result.maxPoints }}</th>
            </tr>
            </tbody>
          </table>
          <div class="btn-group float-end">
            <RouterLink to="/list" class="btn btn-sm btn-primary">
              {{t('page.result.back')}}
            </RouterLink>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import Layout from "@/components/Layout.vue";
import Navigation from "@/components/Navigation.vue";
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import {MainService} from "@/services/main.service";
import {ResultModel} from "@/models/result.model";
import {useTranslate} from "@/hooks/translate.hook";

const t = useTranslate()
const route = useRoute()
const router = useRouter()
const result = ref<ResultModel>()

onMounted(async () => {
  try {
    const id = Number.parseInt(route.params.id as string)
    const rsp = await MainService.getStudentTestResult(id)
    result.value = rsp.data
  } catch (e) {
    await router.push({
      path: '/list'
    })
  }
})

function generateTestTitle() {
  if (result.value == undefined)
    return t('page.result.text.error')

  if (result.value?.isStopped == true) {
    return t('page.result.text.stopped')
  }

  if (result.value?.deadlineReached == true) {
    return t('page.result.text.deadline')
  }

  if (result.value?.points < (result.value?.maxPoints / 2)) {
    return t('page.result.text.failed')
  }

  return t('page.result.text.passed')
}
</script>

<style>
.fixed-columns th {
  width: 100px;
}
</style>