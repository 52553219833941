<template>
  <Navigation :show="false" :logo="false"/>
  <div class="login">
    <form class="form-responsive mx-auto mt-5" v-on:submit.prevent="onClick">
      <div class="mb-3">
        <img src="@/assets/logo.svg" class="rounded mx-auto d-block login-logo">
      </div>
      <div class="mb-3">
        <label for="index" class="form-label">{{ t("page.login.index.title") }}</label>
        <input type="text" class="form-control" id="index" aria-describedby="index-help" v-model="index">
        <div id="index-help" class="form-text">{{ t("page.login.index.hint") }}</div>
      </div>
      <div class="mb-3">
        <label for="umcn" class="form-label">{{ t("page.login.umcn.title") }}</label>
        <input type="password" class="form-control" id="umcn" aria-describedby="umcn-help" v-model="umcn">
        <div id="umcn-help" class="form-text">{{ t("page.login.umcn.hint") }}</div>
      </div>
      <button type="submit" class="btn btn-primary">{{ t("page.login.button") }}</button>
    </form>
  </div>
</template>

<script lang="ts" setup>
import {onBeforeMount, ref} from 'vue';
import {useRouter} from 'vue-router';
import {SessionManager} from '@/services/session.manager'
import {MainService} from '@/services/main.service';
import {useHasActiveTest} from "@/hooks/hastest.hook";
import {AlertManager} from "@/services/alert.manager";
import Navigation from "@/components/Navigation.vue";
import {useTranslate} from "@/hooks/translate.hook";

const index = ref<string>('')
const umcn = ref<string>('')

const router = useRouter()
const hasTest = useHasActiveTest()
const t = useTranslate()

async function onClick() {
  if (index.value == '' || umcn.value == '') {
    AlertManager.error(t('page.login.error.missing'))
    return
  }

  const loading = AlertManager.showLoading()
  MainService.login(index.value, umcn.value)
      .then(rsp => {
        if (rsp.data == undefined || "statusCode" in rsp.data) {
          loading.close()
          AlertManager.error(t('page.login.error.bad'))
          return
        }
        SessionManager.saveAuth(rsp.data)
        loading.close()
        router.push({
          path: '/list'
        })
      })
      .catch(e => {
        loading.close()
        AlertManager.error(t('page.login.error.bad'))
      })
}

onBeforeMount(() => {
  // Return student to test list
  if (SessionManager.hasAuth()) {
    hasTest()
    MainService.getStudent().then(rsp => {
      // Student is already singed in
      router.push({
        path: '/list'
      })
    })
  }
})


</script>

<style>
.form-responsive {
  width: 50%;
}

.login-logo {
  width: 180px;
  height: 100px;
}

@media (max-width: 900px) {
  .form-responsive {
    width: 100%;
  }
}

html[data-bs-theme='dark'] .login-logo {
  filter: invert();
}
</style>
