import {ResponseModel} from "@/models/response.model";
import {TokenModel} from "@/models/token.model";
import axios, {AxiosError, AxiosResponse} from "axios"
import {SessionManager} from "./session.manager";
import {StudentModel} from "@/models/student.model";
import {TimeModel} from "@/models/time.model";
import sha1 from "sha1";

const client = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL || '/',
    headers: {
        'Accept': 'application/json'
    },
    validateStatus: function () {
        return true
    }
})

export class MainService {
    public static async login(index: string, umcn: string): Promise<AxiosResponse<TokenModel, ResponseModel>> {
        return await client.request({
            url: '/auth/student/login',
            method: 'post',
            data: {
                username: index,
                password: umcn
            }
        })
    }

    public static async useAxios(url: string, method: string = 'GET', body: object = {}) {
        // Attempting to retrieve data
        let rsp: AxiosResponse;

        try {
            rsp = await client.request({
                url: url,
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${SessionManager.getAccessToken()}`
                },
                data: body
            }) as AxiosResponse;
        } catch (e) {
            rsp = (e as AxiosError).response as AxiosResponse
        }

        if (rsp == undefined) {
            throw new Error('BACKEND_UNREACHABLE')
        }

        if (rsp.status === 401) {
            // Refresh token
            try {
                const tokenReq = await client.request({
                    url: '/auth/refresh',
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json'
                    },
                    data: {
                        token: SessionManager.getRefreshToken()
                    }
                })


                // Save token
                SessionManager.saveAuth(tokenReq.data)

                // Repeating the primary request
                return await client.request({
                    url: url,
                    method: method,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${SessionManager.getAccessToken()}`
                    },
                    data: body
                });
            } catch (e) {
                SessionManager.clearAuth()
                throw new Error('REFRESH_FAILED')
            }
        }

        if (rsp.status == 404) {
            throw new Error('NOT_FOUND')
        }

        if (rsp.data.statusCode != undefined && rsp.data.statusCode != 0) {
            const code = rsp.data.statusCode
            if (code == 1000) throw new Error('NOT_FOUND')
            if (code == 1001) throw new Error('DUPLICATE_ENTRY')
            if (code == 1002) throw new Error('CREATE_FAILED')
            if (code == 1003) throw new Error('UPDATE_FAILED')
            if (code == 1004) throw new Error('DELETE_FAILED')
            if (code == 1005) throw new Error('EXPORT_FAILED')
            if (code == 1006) throw new Error('IMPORT_FAILED')
            if (code == 1007) throw new Error('BAD_CREDENTIALS')
            if (code == 2000) throw new Error('TEST_ALREADY_ACTIVE')
            if (code == 2001) throw new Error('TEST_NOT_FOUND')
            if (code == 2002) throw new Error('STUDENT_NOT_EMPLOYED')
            if (code == 2003) throw new Error('STUDENT_NOT_ALLOWED_ONLINE')
            if (code == 2004) throw new Error('MAX_ATTEMPTS_REACHED')
            if (code == 2005) throw new Error('MISSING_EXAM_REGISTRATION')
            throw new Error(`${rsp.data.statusCode}: ${rsp.data.message}`)
        }

        // Return response
        return rsp;
    }

    public static async getServerTime(): Promise<AxiosResponse<TimeModel, ResponseModel>> {
        return await this.useAxios('/api/system/time')
    }

    public static async getStudent(): Promise<AxiosResponse<StudentModel, ResponseModel>> {
        return await this.useAxios('/api/student/' + SessionManager.getStudentId())
    }

    public static async getAvailableTests() {
        return await this.useAxios('/api/test/student/' + SessionManager.getStudentId())
    }

    public static async startStudentTest(id: number) {
        return await this.useAxios('/api/work/start', 'post', {
            testId: id,
            studentId: SessionManager.getStudentId()
        })
    }

    public static async getNextStudentTestQuestion() {
        const studentId = SessionManager.getStudentId()
        return await this.useAxios(`/api/work/next/${studentId}`);
    }

    public static async getActiveTest() {
        return await this.useAxios('/api/work/active/' + SessionManager.getStudentId())
    }

    public static async sendAnswers(answers: string[] | undefined) {
        if (answers == undefined)
            throw new Error('UNDEFINED')

        const payload = answers.map(a => {
            return sha1(a)
        })

        const studentId = SessionManager.getStudentId();
        return await this.useAxios(`/api/work/answer/${studentId}`, 'post', {
            answers: payload
        })
    }

    public static async getLatestStudentResults() {
        return await this.useAxios(`/api/work/student/${SessionManager.getStudentId()}/recent`)
    }

    public static async finishCurrentStudentTest(id: number) {
        return await this.useAxios(`/api/work/finish/${id}`, 'post')
    }

    public static async getStudentTestResult(workId: number) {
        return await this.useAxios(`/api/work/${workId}/result`)
    }

    public static async saveStudentCheatingAttempt() {
        return await this.useAxios('/api/work/cheating/' + SessionManager.getStudentId(), 'post')
    }

    static async getCurrentLocation() {
        return await this.useAxios('/api/location/current')
    }

    public static async uploadImage(workId : number, file : File) {
        const formData = new FormData();
        formData.append("workId", workId.toString());
        formData.append("image", file);
        return await this.useAxios(`/api/image`, "post", formData);
    }

}