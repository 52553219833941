import {useRouter} from "vue-router";
import {SessionManager} from "@/services/session.manager";

export function useLogout() {
    const router = useRouter()

    function logout() {
        SessionManager.clearAuth()
        router.push({
            path: '/login'
        })
    }

    return logout
}