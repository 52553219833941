import {MainService} from "@/services/main.service";
import {useRouter} from "vue-router";

export function useHasActiveTest() {
    const router = useRouter()

    function hasActiveTest() {
        MainService.getActiveTest()
            .then(rsp => {
                router.push({
                    path: '/test'
                })
            }).catch(e => {
        })
    }

    return hasActiveTest;
}