<template>
  <li class="nav-item">
    <RouterLink class="nav-link" :to="to">{{ name }}</RouterLink>
  </li>
</template>

<script setup lang="ts">
import {RouterLink} from "vue-router"
import {defineProps} from 'vue'

export interface NavigationRouteProps {
  to: string
  name: string
}

const {to, name} = defineProps<NavigationRouteProps>()
</script>