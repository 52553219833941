<template>
  <Navigation/>
  <Layout>
    <template v-slot:main>
      <div class="card mb-3">
        <h5 class="card-header">{{ t("page.list.test.title") }}</h5>
        <div class="card-body">
          <div class="card-text" v-if="Object.keys(subjects).length > 0">
            <div class="subject" v-for="subject in subjects">
              <h6>{{ subject.title }}</h6>
              <table class="table table-hover">
                <tbody ref="tbody">
                <Test v-for="test in subject.tests" :test="test"
                      :selected="test.testId == selectedTest?.testId" @click="selectTest(test)"/>
                </tbody>
              </table>
            </div>
          </div>
          <p class="card-text" v-else>{{ t("page.list.test.fallback") }}</p>
          <div class="btn-group float-end">
            <button type="button" class="btn btn-sm btn-secondary" @click="loadAvailableTests()">
              {{ t("page.list.test.refresh") }}
            </button>
            <button type="button" class="btn btn-sm btn-primary" :disabled="selectedTest == undefined"
                    @click="startTest()">{{ t("page.list.test.start") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card mb-3" v-if="selectedTest?.requireCamera">
        <h5 class="card-header">{{ t("page.list.camera.title") }}</h5>
        <div class="card-body">
          <img src="@/assets/da-ne-kamera.png" class="card-img">
        </div>
      </div>
      <div class="card mb-3">
        <h5 class="card-header">{{ t("page.list.setup.title") }}</h5>
        <div class="card-body">
          <p class="card-text">
            <RouterLink to="/setup" class="fw-bold">{{ t("page.list.setup.click") }}</RouterLink>
            {{ t("page.list.setup.text") }}
          </p>
        </div>
      </div>
      <div class="card mb-3">
        <h5 class="card-header"><i class="fa-solid fa-triangle-exclamation"></i> {{ t("page.list.note.title") }}</h5>
        <div class="card-body">
          <p class="card-text">{{ t("page.list.note.text") }}</p>
          <ol>
            <li>{{ t(`page.list.note.rules.0`) }}</li>
            <li>{{ t(`page.list.note.rules.1`) }}</li>
            <li>{{ t(`page.list.note.rules.2`) }}</li>
            <li>{{ t(`page.list.note.rules.3`) }}</li>
            <li>{{ t(`page.list.note.rules.4`) }}</li>
            <li>{{ t(`page.list.note.rules.5`) }}</li>
            <li>{{ t(`page.list.note.rules.6`) }}</li>
          </ol>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import Layout from '@/components/Layout.vue';
import Navigation from '@/components/Navigation.vue';
import Test from '@/components/Test.vue';
import {SubjectModel} from '@/models/subject.model';
import {TestModel} from '@/models/test.model';
import {MainService} from '@/services/main.service';
import {onBeforeMount, onBeforeUnmount, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useHasActiveTest} from "@/hooks/hastest.hook";
import {AlertManager} from "@/services/alert.manager";
import {useTranslate} from "@/hooks/translate.hook";

const subjects = ref<SubjectModel[]>([])
const hasTest = useHasActiveTest()
const t = useTranslate()
let fatalShown = false

function loadAvailableTests() {
  MainService.getAvailableTests().then(rsp => {
    subjects.value = rsp.data
  }).catch(e => {
    if (e.message == 'BACKEND_UNREACHABLE' && !fatalShown) {
      fatalShown = true
      AlertManager.fatal(t('error.backend'))
    }
  })
}

const selectedTest = ref<TestModel>()

function selectTest(test: TestModel) {
  selectedTest.value = test
}

const router = useRouter()

function startTest() {
  if (selectedTest.value == undefined) return
  AlertManager.confirm(
      t("page.list.test.alert.title"),
      t("page.list.test.alert.desc") + selectedTest.value.title,
      t("page.list.test.alert.yes"),
      t("page.list.test.alert.no"),
      async () => {
        const loading = AlertManager.showLoading()

        // Camera check
        const cam = await hasCameraAccess()
        const mand = selectedTest.value!.requireCamera
        if (!mand || (mand && cam)) {
          MainService.startStudentTest(selectedTest.value!.testId).then(rsp => {
            router.push({
              path: '/test'
            })
            loading.close()
          }).catch(e => {
            loading.close()
            AlertManager.error(t(e.message).startsWith("error.") ? t('error.start') : t("error." + e.message))
          })
          return
        }

        // No camera found
        await loading.close()
        AlertManager.error(t('error.camera'))
      })
}

async function hasCameraAccess(): Promise<boolean> {
  try {
    // Try to access the user's camera
    await navigator.mediaDevices.getUserMedia({video: true});

    // If successful, the user has allowed camera access
    return true;
  } catch (error) {
    // If there is an error, the user has not allowed camera access
    return false;
  }
}

onBeforeMount(() => {
  hasTest()
  loadAvailableTests()
})

// auto update data every 15 sec
const interval = setInterval(loadAvailableTests, 15000)
onBeforeUnmount(() => clearInterval(interval));
</script>