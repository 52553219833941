import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/js/all.js'
import '@fortawesome/fontawesome-free/css/all.css'
import {setLocale} from "@/services/localisation";

await setLocale(localStorage.getItem('language') ?? 'sr')

createApp(App)
    .use(router)
    .mount('#app')
